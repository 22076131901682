.mainContainer {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
}
.mainContainer > h1 {
  margin-top: 0;
  margin-bottom: -4px;
  font-size: 46px;
}
.mainContainer > span {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 20px;
}
.mainContainer > p {
  font-size: 32px;
}
.sub-text {
  width: 580px;
  padding-bottom: 22px;
}
.step {
  min-height: 400px;
}
.step-7 .sub-text {
  width: 380px;
}
.step-finish span {
  width: 420px;
}
.step-finish > p {
  margin: 32px 0;
  font-size: 30px;
}
.finish-data {
  margin-top: 32px;
  text-align: left;
}
.finish-data > p {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
}
.finish-data p span {
  font-weight: 600;
}
.mt-32 {
  margin-top: 40px;
}
.step-2 > button {
  margin: 22px 0;
}

@media only screen and (max-width: 800px) {
  .step {
    min-height: 300px;
  }
  .step-5 {
    min-height: 370px;
  }
  .mainContainer {
    margin-bottom: 0;
    justify-content: flex-start;
  }
  .mainContainer > h1 {
    padding-top: 16px;
    font-size: 30px;
  }
  .mainContainer > span {
    font-size: 16px;
  }
  .mainContainer > p {
    font-size: 20px;
  }
  .sub-text,
  .step-finish > span {
    width: unset;
  }
  .mt-32 {
    margin-top: 100px;
  }
  .step-finish img {
    width: 70px;
  }
  .step-finish > p {
    margin: 22px 0;
    font-size: 22px;
  }
  .step-finish > span {
    margin-bottom: 32px;
  }
  .finish-data {
    margin-top: 22px;
  }
  .finish-data > p {
    font-size: 16px;
  }
  .finish-data > span {
    font-size: 14px;
  }
}
