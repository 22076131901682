@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  color: #fff;
  background: #0697d9;
}
.link-pages {
  border-top: 1px solid #fff;
  padding: 22px 0;
}
.link-pages > a {
  color: #fff;
  text-decoration: none;
  margin: 0 32px;
  font-size: 24px;
}
.container {
  height: 100%;
  width: 100%;
  background-image: url("/static/media/Mask Group 1.e17c85b1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 1;
  text-align: center;
}
.info h1 {
  font-size: 42px;
  text-align: left;
}
.underline {
  text-decoration: underline;
  text-align: left;
}
.max-width{
  width: 100%;
}
.header {
  padding-top: 32px;
}
.header .item img {
  height: 40px;
  margin: 0;
}
.header span {
  font-size: 22px;
}
.header p {
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}
/* .main {
  padding: 64px 0;
} */
.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 100%;
  color: #fff;
  padding: 64px 0;
}
.d-flex-sb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.item {
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.item img {
  margin-right: 20px;
  margin-bottom: 8px;
}
.footer .item img {
  width: 60px;
}
.item p {
  margin: 0;
  text-align: left;
}
.large {
  font-size: 30px;
  font-weight: 600;
}

@media only screen and (max-width: 800px) {
  .link-pages > span {
    display: none;
  }
  .link-pages a {
    color: #fff;
    margin: 0;
    text-align: end;
    margin-top: 4px;
    font-size: 18px;
  }
  .link-pages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer {
    width: unset;
  }
  .footer.d-flex-sb {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .item {
    margin-bottom: 16px;
  }
  .header {
    padding-bottom: 22px 0;
  }
  .header .item img {
    height: 28px;
    margin: 0;
  }
  .header span {
    display: none;
  }
  .header p {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .footer {
    padding: 32px 0;
  }
  .footer .item:last-child {
    margin-bottom: 0;
  }
  .footer .item img {
    width: 50px;
    margin-bottom: 0;
  }
  .footer .item p {
    font-size: 20px;
  }
  .footer .item p.large {
    font-size: 24px;
  }
  .info h1 {
    font-size: 32px;
  }
}

.mainContainer {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
}
.mainContainer > h1 {
  margin-top: 0;
  margin-bottom: -4px;
  font-size: 46px;
}
.mainContainer > span {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 20px;
}
.mainContainer > p {
  font-size: 32px;
}
.sub-text {
  width: 580px;
  padding-bottom: 22px;
}
.step {
  min-height: 400px;
}
.step-7 .sub-text {
  width: 380px;
}
.step-finish span {
  width: 420px;
}
.step-finish > p {
  margin: 32px 0;
  font-size: 30px;
}
.finish-data {
  margin-top: 32px;
  text-align: left;
}
.finish-data > p {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
}
.finish-data p span {
  font-weight: 600;
}
.mt-32 {
  margin-top: 40px;
}
.step-2 > button {
  margin: 22px 0;
}

@media only screen and (max-width: 800px) {
  .step {
    min-height: 300px;
  }
  .step-5 {
    min-height: 370px;
  }
  .mainContainer {
    margin-bottom: 0;
    justify-content: flex-start;
  }
  .mainContainer > h1 {
    padding-top: 16px;
    font-size: 30px;
  }
  .mainContainer > span {
    font-size: 16px;
  }
  .mainContainer > p {
    font-size: 20px;
  }
  .sub-text,
  .step-finish > span {
    width: unset;
  }
  .mt-32 {
    margin-top: 100px;
  }
  .step-finish img {
    width: 70px;
  }
  .step-finish > p {
    margin: 22px 0;
    font-size: 22px;
  }
  .step-finish > span {
    margin-bottom: 32px;
  }
  .finish-data {
    margin-top: 22px;
  }
  .finish-data > p {
    font-size: 16px;
  }
  .finish-data > span {
    font-size: 14px;
  }
}

